@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.scroll-overlay {
  overflow: overlay;
}

.font-poppins {
  font-family: 'Poppins', sans-serif;
}

.scroller {
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #fb7c51 #ffffff;
}
.scroller::-webkit-scrollbar {
  width: 7.5px;
  height: 7.5px;
}
.scroller::-webkit-scrollbar-track {
  background: #ffffff; /* color of the tracking area */
}

.scroller::-webkit-scrollbar-thumb {
  background-color: #fb7c51; /* color of the scroll thumb */
  border-radius: 2px; /* roundness of the scroll thumb */
}

.departments {
  overflow: hidden;
  border-radius: 10px;
  th {
    text-align: left;
  }

  th:nth-child(1) {
    width: 10%;
    text-align: center;
  }

  th:nth-child(2) {
    width: 20%;
  }

  th:nth-child(3) {
    width: 30%;
  }

  th:nth-child(4) {
    width: 30%;
  }

  th:nth-child(5) {
    width: 10%;
  }

  td:first-child {
    width: 10%;
  }

  td:nth-child(2) {
    width: 20%;
  }

  td:nth-child(3) {
    width: 30%;
  }

  td:nth-child(4) {
    width: 30%;
  }

  td:nth-child(5) {
    width: 10%;
  }
}

.details-table {
  tr:nth-child(7) {
    border-bottom: 1px solid #ffd8d8;
  }
}
.rbc-header {
  color: #969696;
  padding: 10px 0;
  text-transform: uppercase;
  text-align: start;
}
.rbc-header span {
  margin-left: 9px;
}

.rbc-month-view {
  border-radius: 8px;
  overflow: hidden;
}

.rbc-date-cell {
  text-align: left;
}

.rbc-button-link {
  margin: 0.2rem;
  color: #969696;
  margin-left: 5.8px;
}

.rbc-row:nth-child(1) .rbc-event.rbc-event-allday:first-child {
  background-color: rgb(0, 89, 255);
}
.rbc-row:nth-child(2) .rbc-event.rbc-event-allday:first-child {
  background-color: forestgreen;
}
.rbc-row:nth-child(3) .rbc-event.rbc-event-allday:first-child {
  background-color: pink;
}
.rbc-row:nth-child(4) .rbc-event.rbc-event-allday:first-child {
  background-color: orangered;
}

.rbc-day-bg.rbc-today {
  background-color: #ffe5dc;
}

.rbc-events-container {
  background-color: #ffffff;
}

.rbc-time-slot > .rbc-label {
  color: #969696;
}

.rbc-day-bg.rbc-off-range-bg {
  background-color: #f8f8f8;
}

.rbc-agenda-time-cell {
  border: 1px solid #dddddd;
}

.other-details-table {
  tr {
    border-bottom: 1px solid #f9f9f9;
  }
  tr:nth-child(3) {
    border-bottom: none;
  }
}

.payslips-table {
  th {
    padding: 30px !important;
    font-size: 18px !important;
  }

  tbody td {
    font-size: 18px !important;
  }

  tr:first-child td {
    color: #fb7c51;
  }
}

.w-120h {
  height: calc(100% + 15px);
}

.w-120 {
  width: calc(100% + 10px);
}

.jodit-toolbar-button__button {
  background-color: #ffffff;
}

.jodit-container:not(.jodit_inline) {
  border: 1px solid #fb7c51;
  border-radius: 3px;
}

.jodit-icon {
  fill: #fb7c51;
}

.jodit-status-bar {
  color: #fb7c51;
}

.jodit-status-bar-link {
  cursor: pointer;
  display: block;
}
.jodit-add-new-line {
  display: none;
}

.departments-super-admin {
  .mantine-Accordion-control {
    padding: 0 !important;
  }

  .mantine-Accordion-icon {
    margin-left: 0 !important;
    width: 0 !important;
    min-width: 0 !important;
  }
}

.line-clamp {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.rbc-toolbar button {
  border: none;
}

.swatchBox {
  float: left;
  width: 20px;
  height: 20px;
  margin: 5px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: url('../src/assets/icons/clockIcon.svg') no-repeat;
}

@media (min-width: 1920px) {
  .rbc-header {
    font-size: 1rem;
  }
  .mantine-exqcaf:checked::before {
    transform: translateX(25px);
  }
}

.custom-indicator {
  .mantine-Indicator-indicator {
    padding: 0 !important;
    border: none !important;
    background-color: transparent !important;
    z-index: 60 !important;
  }
}

input[type='time']::-webkit-calendar-picker-indicator {
  background: url('../src/assets/icons/clockIcon.svg');
  background-repeat: no-repeat;
  background-position: center;
  display: none;
}
.rbc-date-cell {
  position: relative;
}
// .rbc-button-link {
//   // background-color: red;
//   position: fixed;

//   z-index: 10;
// }

// .rbc-row {
//   overflow-x: hidden;
//   overflow-y: auto;
//   height: 30px;
// }
// .rbc-day-bg {
//   overflow-x: hidden;
//   overflow-y: scroll;
//   // height: max-content;
//   scrollbar-width: thin; /*thin;*/
//   scrollbar-color: #fb7c51 #ffffff;
// }
.rbc-month-header {
  overflow-x: hidden;
  overflow-y: scroll;
}
.rbc-month-row {
  overflow-x: hidden;
  overflow-y: scroll;
  height: max-content;
  scrollbar-width: thin; /*thin;*/
  scrollbar-color: #fb7c51 #ffffff;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: #fb7c51; /* color of the scroll thumb */
  border-radius: 2px; /* roundness of the scroll thumb */
}
.dropdownBody {
  position: fixed !important;
}
